// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

require('moment')
require('jquery')

// import $ from "jquery";
// global.$ = jQuery;

var jQuery = require('jquery')
global.$ = global.jQuery = jQuery;
window.$ = window.jQuery = jQuery;


import UIkit from 'uikit';
import Icons from 'uikit/dist/js/uikit-icons';
UIkit.use(Icons);

import 'slick-carousel';
import 'packs/style';

import 'bootstrap';
import '../stylesheets/front.css'
// import 'stylesheets/home.css'
// import 'stylesheets/footer.css'
// import 'stylesheets/stocks.css'
// import 'stylesheets/inq.css'

const images = require.context('corporate-image', true)
const imagePath = (name) => images(name, true)