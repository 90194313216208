var TK = {};

( function (window, $) {
	'use strict';

	TK.config = {
		bp    : 960,
		wh    : null,
		ww    : null,
		mode  : 'sp',
		uri   : TK.URI,
		url   : TK.URL,
	}

	TK.mvSlider = function () {
		var $slider = $('.top-mv-slide');

		if ($slider.length) {
			$slider
			.not('.slick-initialized')
			.slick();
		}
	}

	TK.imgSwitch = function(judg){
		var imgSwitch = $('.js-imgswitch');
		var suffix = TK.isMaxWidth(640) ? ['_pc.', '_sp.'] : ['_sp.', '_pc.'];

		imgSwitch.each(function(){
			$(this).attr('src', $(this).attr('src').replace(suffix[0], suffix[1]));
		});
	}
	
	TK.lazyload = function () {
		var tag = 'data-src';
		var $images = $('img[' + tag + ']');
		$images.each(function () {
			var $that = $(this);
			$that.attr('src', $that.attr(tag));
			$that.on('load', function () {
				$that.addClass('is-imgLoaded');
			});
		});
	}

	TK.isMaxWidth = function (width) {
		var width = width || TK.config.bp;
		return window.matchMedia('screen and (max-width: ' + width + 'px)').matches;
	}
	
	TK.iframeVideo = function () {}
	TK.accordion = function () {}
	TK.sort = function () {}


	$(function () {
		TK.mvSlider();
		TK.lazyload();
		TK.accordion();
		TK.sort();


		$('[data-delay]').each(function () {
			$(this).css('transition-delay', $(this).attr('data-delay'));
		});


		var $pager = $('.com-pagination');
		if ( $pager[0] ) {
			$pager.find('.prev').parent().addClass('arrow prev');
			$pager.find('.next').parent().addClass('arrow next');
		}


		$('a.js-smoothscroll').on('click', function() {
			var speed = 650;
			var href= $(this).attr('href');
			if (href == '#') return false;
			var target = $(href == '#' || href == '' ? 'html' : href);
			var $offset = $('.header').height();
			var position = Math.ceil(target.offset().top - $offset);
			$('html, body').animate({scrollTop: position}, speed, 'swing');
			return false;
		});
	});
})(window, jQuery);


if(window.matchMedia('(max-width:959px)').matches){
  $('.side-panel-tit').addClass('uk-accordion-title');	
} else {
  $('.side-panel-tit').removeClass('uk-accordion-title');
}
if(window.matchMedia('(max-width:959px)').matches){
  $('.side-panel-content').addClass('uk-accordion-content');	
} else {
  $('.side-panel-content').removeClass('uk-accordion-content');
}
